import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Enviroments
import { environment } from '@environments/environment';

// Interfaces
import { ResponsePageableDTO } from '@interfaces/dto/response/response-pageable-dto.interface';

// Models
import { FormAvaliation } from '@models/form-avaliation.model';

@Injectable({
  providedIn: 'root'
})
export class FormAvaliationService {

  private baseURL = `${environment.baseUrlApi}/${environment.versionApi}`;

  constructor(private http: HttpClient) { }

  save(formAvaliation: FormAvaliation){
    return this.http.post<FormAvaliation>(`${this.baseURL}/enterprise/form-avaliation`, formAvaliation);
  }

  findAll(){
    return this.http.get<Array<FormAvaliation>>(`${this.baseURL}/enterprise/forms-avaliation`);
  }

  findAllPageable(page: number, size: number){
    return this.http.get<ResponsePageableDTO<FormAvaliation>>(`${this.baseURL}/enterprise/forms-avaliation/pageable?page=${page}&size=${size}`);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Models
import { Proposal } from '@models/proposal.model';

// Enviroments
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

  private baseURL = `${environment.baseUrlApi}/${environment.versionApi}`;

  constructor(private http: HttpClient) { }

  add(proposal: Proposal) {
    return this.http.post<Proposal>(`${this.baseURL}/covered/proposal`, proposal);
  }

  updateSaleText(proposal: Proposal) {
    return this.http.put<void>(`${this.baseURL}/moderator/proposal/${proposal.id}/sale-text`, proposal.saleText);
  }

  uploadFile(formData: FormData, proposalId: number){
    return this.http.post(`${this.baseURL}/covered/proposal/${proposalId}/upload`, formData, {reportProgress: true, observe: 'events'});
  }

  findById(id: number){
    return this.http.get<Proposal>(`${this.baseURL}/covered/proposal/${id}`);
  }

  delete(id: number){
    return this.http.delete<Proposal>(`${this.baseURL}/moderator/proposal/${id}`);
  }

  getMean(proposal: Proposal){
    const totalAvaliation = proposal.avaliations.length;
    let mean = 0.0;
    if (totalAvaliation > 0){
      proposal.avaliations.forEach(avaliation => mean = mean + avaliation.grade);
      proposal.mean = mean / totalAvaliation;
    }
    proposal.mean = mean;
  }

}

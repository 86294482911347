<!-- home start -->
<section class="bg-home bg-gradient" id="home">
  <div class="home-center">
    <div class="home-desc-center">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6 text-center">
            <div class="home-title mo-mb-20">
              <h1 class="mb-4 text-white">Pensamos como você!</h1>
              <h3 class="text-white mb-5">Conectando empresas e empreendedores sociais para o uso qualificado das Leis de Incentivo Fiscal.</h3>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
  </div>
  <img src="/assets/images/layer-landing-right.png" alt="" class="layer-banner-hero-right-image">
</section>
<!-- home end -->

import { DocumentType } from '@enums/document-type.enum';
import { Archive } from '@models/archive.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'absolutePath' })
export class AbsolutePathPipe implements PipeTransform {
  transform(files: Archive[], type?: DocumentType): string {
    const file = files.find(file => file.type === type);
    const absolutePath = file.absolutePath;
    return absolutePath;
  }
}


<div class="modal-content">
  <div class="modal-header border">
      <h4 class="modal-title font-weight-bold">Documento {{ this.archive.status === 'DENIED' ? 'Recusado' : 'Expirado'}} - Feedback</h4>
  </div>
  <div class="modal-body">
    <p><b>Data de Solicitação:</b> {{this.archive.feedback.dateRegister | date}}</p>
    <p *ngIf="this.credentialsType != 'ASSOCIATION'"><b>Avaliador:</b> {{this.archive.feedback.user.name + ' ' + this.archive.feedback.user.lastName}}</p>
    <p><b>Feedback:</b> {{this.archive.feedback.feedback}}</p>
  </div>
  <div class="modal-footer border">
    <button type="button" class="btn btn-success" (click)="onClose()">OK</button>
  </div>
</div><!-- /.modal-content -->


import { Archive } from './archive.model';
import { User } from './user.model';

export class Feedback {
  id: number;
  dateRegister: Date;
  feedback: string;
  user: User;

  constructor(){
    this.id = null;
    this.dateRegister = new Date();
    this.feedback = null;
    this.user = null;
  }
}

import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

// Services
import { AuthService } from '@core/auth/auth.service';

@Injectable({
    providedIn: 'root'
  })
  export class HeaderInterceptor implements HttpInterceptor{

    constructor(private authService: AuthService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler){

      if (request.headers.get('skip')){
        request = request.clone({ headers: request.headers.delete('skip') });
        return next.handle(request);
      }

      if (this.authService.isLogged){
        request = request.clone({
          setHeaders: {
              Authorization: `${this.authService.getToken()}`
          }
        });
        return next.handle(request);
      }
      else {
        this.router.navigate(['./painel']);
        return;
      }
    }
  }

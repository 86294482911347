import { Component, Input, OnInit } from '@angular/core';

// Models
import { Archive } from '@models/archive.model';

// Enums
import { CredentialType } from '@enums/credentials-type.enum';

// Services
import { AuthService } from '@core/auth/auth.service';

// Thirds
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-feedback-detail',
  templateUrl: './modal-feedback-detail.component.html',
  styleUrls: ['./modal-feedback-detail.component.scss']
})
export class ModalFeedbackDetailComponent implements OnInit {

  @Input() archive: Archive;
  credentialsType: CredentialType;

  constructor(public modalRef: BsModalRef, private authService: AuthService) { }

  ngOnInit() {
    this.credentialsType = this.authService.getType();
  }

  onClose(){
    this.modalRef.hide();
  }

}

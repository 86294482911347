import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';

// Models
import { Proposal } from './../../../core/models/proposal.model';

// Services
import { ProposalService } from '@services/proposal.service';

// Thirds
import Swal from 'sweetalert2';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-modal-sale-text',
  templateUrl: './modal-sale-text.component.html',
  styleUrls: ['./modal-sale-text.component.css']
})
export class ModalSaleTextComponent implements OnInit {

  // Models
  @Input() proposal: Proposal = new Proposal();
  @Output() withSuccess: EventEmitter<boolean> = new EventEmitter(false);

  // Form
  form: FormGroup;
  loading = false;
  submitted = false;

  subscription: Subscription = new Subscription();

  constructor(public modalRef: BsModalRef, private proposalService: ProposalService, private formBuilder: FormBuilder) { }

  get f() { return this.form.controls; }

  ngOnInit(){
    this.initForm();
  }

  initForm(){
    this.form = this.formBuilder.group({
      saleText: new FormControl('', Validators.required)
    });
  }

  addSaleText(){
    Swal.fire({
      title: 'Enviando ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.subscription.add(this.proposalService.updateSaleText(this.proposal).subscribe({
      next: () => {
        Swal.fire('Texto de Venda Cadastrado', 'Texto de venda cadastrado com sucesso!', 'success');
        this.withSuccess.emit(true);
      },
      error: err => Swal.fire('Ooops!', `${err.error.message}`, 'error')
    }));
  }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    if (this.form.invalid) { this.loading = false; return; }
    this.addSaleText();
    this.modalRef.hide();
    this.loading = false;
  }

  onClose(){
    this.modalRef.hide();
  }

}

import { ArchiveStatus } from '@enums/archive-status.enum';
import { FundAccountBank } from '@models/funk-account-bank.model';
import { Archive } from '@models/archive.model';
import { Association } from '@models/association.model';
import { Legislation } from '@models/legislation.model';

export class Project {
  id: number;
  dateRegister: Date;
  name: string;
  totalValue: number;
  directBeneficiaries: number;
  indirectBeneficiaries: number;
  counterpart: string;
  objective: string;
  status: ArchiveStatus;
  fundAccountBank: FundAccountBank;
  association: Association;
  legislations: Array<Legislation>;
  files: Array<Archive>;
  partners: Array<string>;

  constructor(){
    this.id = null;
    this.dateRegister = new Date();
    this.name = null;
    this.totalValue = null;
    this.directBeneficiaries = null;
    this.indirectBeneficiaries = null;
    this.counterpart = null;
    this.objective = null;
    this.status = ArchiveStatus.PENDING;
    this.fundAccountBank = new FundAccountBank();
    this.association = new Association();
    this.legislations = new Array<Legislation>();
    this.files = new Array<Archive>();
    this.partners = new Array<string>();
  }
}

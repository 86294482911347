<!-- Begin page -->


<div id="wrapper">

  <app-navbar-dashboard></app-navbar-dashboard>

  <!-- ============================================================== -->
  <!-- Start Page Content here -->
  <!-- ============================================================== -->
  <div class="content-page">
      <div class="content">

          <!-- Start Content-->
          <div class="container-fluid">

            <router-outlet></router-outlet>

          </div> <!-- container -->

      </div> <!-- content -->
      <app-footer-dashboard></app-footer-dashboard>
  </div>
  <!-- ============================================================== -->
  <!-- End Page content -->
  <!-- ============================================================== -->

</div>



<!-- END wrapper -->

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { AuthGuard } from '@core/guards/auth.guard';

// Components
// import { HomePageComponent } from '@pages/home-page/home-page.component';
import { LoginPageComponent } from '@pages/login-page/login-page.component';
import { DefaultTemplateComponent } from '@pages/painel-page/default-template/default-template.component';

const routes: Routes = [

  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'painel',
    component: DefaultTemplateComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('@pages/painel-page/painel.module').then(
      m => m.PainelModule
    )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

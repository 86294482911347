
<div class="modal-content">
  <div class="modal-header border">
      <h4 class="modal-title font-weight-bold">Vincular Formulário de Avaliação</h4>
  </div>
  <div class="modal-body">
    <div class="row mt-2" style="max-height: 75vh; overflow-y: auto;">
      <div class="col">
        <p class="lead" [ngClass]="{'text-danger': this.error}">
          Selecione o formulário desejado:
        </p>
        <label *ngFor="let formAvaliation of (this.formsAvaliation$ | async)" (click)="this.onSelected(formAvaliation.id)">
          <input type="radio" name="demo" class="card-input-element d-none" [id]="formAvaliation.id" [checked]="this.selectedFormAvaliation === formAvaliation.id">
          <div class="card card-body bg-primary text-white d-flex flex-row justify-content-between align-items-center" [ngClass]="{'bg-success border-success': this.selectedFormAvaliation === formAvaliation.id}"> {{ formAvaliation.name }} </div>
        </label>
      </div>
    </div>
  </div>
  <div class="modal-footer border">
    <button type="button" class="btn btn-success" (click)="onSubmit()">Vincular</button>
    <button type="button" class="btn btn-danger" (click)="onClose()">Fechar</button>
  </div>
</div><!-- /.modal-content -->


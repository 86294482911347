import { Bank } from './bank.model';
import { AccountBankType } from '@enums/account-bank-type.enum';
export class FundAccountBank {
  id: number;
  cnpj: string;
  bank: Bank;
  accountNumber: string;
  agency: string;
  type: AccountBankType;

  constructor(){
    this.id = null;
    this.cnpj = null;
    this.bank = new Bank();
    this.accountNumber = null;
    this.agency = null;
    this.type = null;
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-dashboard',
  templateUrl: './breadcrumb-dashboard.component.html',
  styleUrls: ['./breadcrumb-dashboard.component.css']
})
export class BreadcrumbDashboardComponent implements OnInit {

  @Input() title: string;
  @Input() breadcrumb: any;

  constructor() { }

  ngOnInit(): void { }

}

import { AccountBankType } from '@enums/account-bank-type.enum';
import { Bank } from './bank.model';

export class AccountBank {
  id: number;
  bank: Bank;
  accountNumber: string;
  agency: string;
  type: AccountBankType;

  constructor(){
    this.id = null;
    this.bank = null;
    this.accountNumber = null;
    this.agency = null;
    this.type = null;
  }

}

import { TopicFormAvalation } from './topic-form-avaliation.model';

export class FormAvaliation {
  id: number;
  dateRegister: Date;
  name: string;
  topics: Array<TopicFormAvalation>;

  constructor() {
    this.id = null;
    this.dateRegister = new Date();
    this.name = null;
    this.topics = new Array<TopicFormAvalation>();
  }
}

import { Component, Input, OnInit } from '@angular/core';

// Model
import { TopicFormAvalation } from '@models/topic-form-avaliation.model';

@Component({
  selector: 'app-modal-form-avaliation-topic-detail',
  templateUrl: './modal-form-avaliation-topic-detail.component.html',
  styleUrls: ['./modal-form-avaliation-topic-detail.component.scss']
})
export class ModalFormAvaliationTopicDetailComponent implements OnInit {

  @Input() topic: TopicFormAvalation;
  @Input() index: number;

  isCollapsed = true;

  constructor() { }

  ngOnInit() {
  }

}


<div class="modal-content">
  <div class="modal-header border">
      <h3 class="modal-title font-weight-bold">{{ formAvaliation.name }}</h3>
  </div>
  <div class="modal-body">
    <app-modal-form-avaliation-topic-detail [topic]="topic" [index]="i + 1" *ngFor="let topic of formAvaliation.topics; index as i"></app-modal-form-avaliation-topic-detail>
  </div>
  <div class="modal-footer border">
    <button type="button" class="btn btn-success" (click)="onClose()">Fechar</button>
  </div>
</div><!-- /.modal-content -->


import { ViaCepService } from './via-cep.service';

// Modules
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [],
  providers: [ViaCepService]
})
export class ViaCepModule { }

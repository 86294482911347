import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViaCepService {

  constructor(private http: HttpClient) { }

  findByZipCode(zipCode: string) {
    return this.http.get<any>(`https://viacep.com.br/ws/${zipCode}/json`, {headers: { skip: 'true' }});
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Models
import { Proposal } from './../models/proposal.model';
import { Avaliation } from './../models/avaliation.model';

// Interfaces
import { QuestionResultRequestDTO } from './../interfaces/dto/request/question-result-request-dto.interface';

// Enviroments
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AvaliationService {

  private baseURL = `${environment.baseUrlApi}/${environment.versionApi}`;

  constructor(private http: HttpClient) { }

  save(avaliationRequestDTO: Array<QuestionResultRequestDTO>, proposal: Proposal){
    return this.http.post<Avaliation>(`${this.baseURL}/enterprise/avaliation?proposalId=${proposal.id}`, avaliationRequestDTO);
  }

}

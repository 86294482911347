<div class="loading auth-fluid-pages pb-0">
  <div class="auth-fluid">
    <!--Auth fluid left content -->
    <div class="auth-fluid-form-box">
      <div class="align-items-center d-flex h-100">
        <div class="card-body">

          <!-- Logo -->
          <div class="auth-brand text-lg-left">
            <div class="auth-logo">
              <div class="align-items-center d-flex justify-content-between flex-wrap">
                <img class="logo-brand mx-auto" src="../assets/images/logo-brand.png" alt="" height="70"> <span class="brand-name text-center ml-2">Leroy Merlin</span>
              </div>
            </div>
          </div>

          <!-- title-->
          <h4 class="mt-0">Área do Usuário</h4>
          <p class="text-muted mb-4">Entre com seu e-mail e senha para acessar a plataforma.</p>

          <!-- form -->
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="form-group">
              <label for="emailaddress">E-mail:</label>
              <div class="input-group input-group-merge">
                <input formControlName="username" class="form-control" type="email" placeholder="Digite seu e-mail" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                <div class="input-group-append">
                    <div class="input-group-text">
                      <i class="fas fa-user-shield"></i>
                    </div>
                </div>
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">E-mail é obrigatório</div>
                </div>
              </div>
            </div>
            <div class="form-group">
                <a class="text-muted float-right" (click)="this.recoveryPassword()"><small>Esqueceu sua senha?</small></a>
                <label for="password">Senha</label>
                <div class="input-group input-group-merge">
                  <input formControlName="password" class="form-control" type="password" placeholder="Digite sua senha" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                  <div class="input-group-append" data-password="false">
                    <div class="input-group-text">
                      <i class="fas fa-key"></i>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Senha é obrigatório</div>
                  </div>
                </div>
            </div>

            <div class="form-group mb-0 text-center">
              <button [disabled]="loading" class="btn btn-grad-primary mx-2" type="submit">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> <i class="fas fa-sign-in-alt mr-1"></i> Entrar
              </button>
              <button class="btn btn-grad-primary mx-2" [routerLink]="['/']">
                <i class="fas fa-home mr-1"></i> Voltar
              </button>
            </div>

            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
          </form>
          <!-- end form-->

          <!-- Footer-->
          <footer class="footer footer-alt">
              <p class="text-dark">Não possui conta?<br><a class="text-primary-gradient" (click)="this.register()" style="font-weight: bold;"><b>Cadastre-se</b></a></p>
          </footer>

        </div> <!-- end .card-body -->
      </div> <!-- end .align-items-center.d-flex.h-100-->
    </div>
    <!-- end auth-fluid-form-box-->

    <!-- Auth fluid right content -->
    <div class="auth-fluid-right text-center">
      <div class="auth-user-testimonial">
        <h5 class="text-left text-white">Propriedade e Gestão da Yabá Consultoria</h5>
      </div> <!-- end auth-user-testimonial-->
    </div>
    <!-- end Auth fluid right content -->
  </div>
  <!-- end auth-fluid-->
</div>

<!-- Footer Start -->
<footer class="footer">
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-6">
              <a class="text-white" href="https://www.incentivados.com.br" target="_blank">{{ currentYear | date: 'yyyy' }} &copy; Plataforma Incentivados Versão: 2.2.8</a>
          </div>
          <div class="col-md-6">
              <div class="text-md-right footer-links d-none d-sm-block">
                  <a href="javascript:void(0);">Sobre Nós</a>
                  <a href="javascript:void(0);">Ajuda</a>
                  <a href="javascript:void(0);">Contato</a>
              </div>
          </div>
      </div>
  </div>
</footer>
<!-- end Footer -->

export class Bank {
  id: number;
  cod: number;
  name: string;

  constructor(){
    this.id = null;
    this.cod = null;
    this.name = null;
  }
}

export enum CredentialType {
  ASSOCIATION = 'ASSOCIATION',
  ENTERPRISE = 'ENTERPRISE',
  GUEST = 'GUEST',
  MODERATOR = 'MODERATOR',
  ADMIN = 'ADMIN'
}

export const credentialsTypeLabel = new Map<CredentialType, string>([
  [CredentialType.ASSOCIATION, 'Proponente'],
  [CredentialType.ENTERPRISE, 'Empresa'],
  [CredentialType.GUEST, 'Convidado'],
  [CredentialType.MODERATOR, 'Moderador'],
  [CredentialType.ADMIN, 'Admin']
]);

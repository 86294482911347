import { Association } from '@models/association.model';
import { Archive } from '@models/archive.model';
import { Tenant } from '@models/tenant.model';
import { Credentials } from '@models/credentials.model';
import { Address } from '@models/address.model';

export class User{
  id: number;
  name: string;
  lastName: string;
  phone: string;
  cpf: string;
  credentials: Credentials;
  avatar: Archive;
  address: Address;
  tenant: Tenant;
  associations: Array<Association>;

  constructor(){
    this.id = null;
    this.name = null;
    this.lastName = null;
    this.phone = null;
    this.cpf = null;
    this.credentials = new Credentials();
    this.avatar = new Archive();
    this.address = new Address();
    this.tenant = new Tenant();
    this.associations = new Array();
  }
}

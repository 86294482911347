<!-- Topbar Start -->
<div class="navbar-custom">
  <div class="container-fluid">
      <ul class="list-unstyled topnav-menu float-right mb-0">

          <li class="dropdown d-inline-block d-lg-none">
              <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                  <i class="fe-search noti-icon"></i>
              </a>
              <div class="dropdown-menu dropdown-lg dropdown-menu-right p-0">
                  <form class="p-3">
                      <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                  </form>
              </div>
          </li>

          <li class="dropdown d-none d-lg-inline-block">
              <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light" appToggleDashboardFullScreen>
                  <i class="fe-maximize noti-icon"></i>
              </a>
          </li>

          <li class="dropdown notification-list topbar-dropdown" ngbDropdown>
              <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" ngbDropdownToggle>
                  <img [src]="'/assets/images/users/user-no-image.jpg'" class="rounded-circle" alt="profile-image" *ngIf="(this.user$ | async).credentials.avatar === null">
                  <img [src]="(this.user$ | async).credentials.avatar.absolutePath" class="rounded-circle" alt="profile-image" *ngIf="(this.user$ | async).credentials.avatar != null">
                  <span class="pro-user-name ml-1">
                      {{ (user$ | async).name }} <i class="mdi mdi-chevron-down"></i>
                  </span>
              </a>
              <div class="dropdown-menu dropdown-menu-right profile-dropdown" ngbDropdownMenu>
                  <!-- item-->
                  <div class="dropdown-header noti-title">
                      <h6 class="text-overflow m-0">Bem vindo !</h6>
                  </div>

                  <!-- item-->
                  <a [routerLink]="['/painel/minha-conta']" class="dropdown-item notify-item">
                      <i class="fe-user"></i>
                      <span>Minha Conta</span>
                  </a>

                  <!-- item-->
                  <a class="dropdown-item notify-item" (click)="logout()">
                      <i class="fe-log-out"></i>
                      <span>Logout</span>
                  </a>

              </div>
          </li>

      </ul>


      <div class="logo-box">
        <a href="index.html" class="logo logo-light text-center">
          <span class="logo-lg">
            <img src="/assets/images/logo-incentivados-branco.png" alt="" height="48">
          </span>
        </a>
      </div>

      <ul class="list-unstyled topnav-menu topnav-menu-left m-0">

        <li>
            <button class="button-menu-mobile waves-effect waves-light" appToggleDashboardMenu>
                <i class="fe-menu"></i>
            </button>
        </li>

        <li>
            <!-- Mobile menu toggle (Horizontal Layout)-->
            <a class="navbar-toggle nav-link" data-toggle="collapse" data-target="#topnav-menu-content">
                <div class="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </a>
            <!-- End mobile menu toggle-->
        </li>
      </ul>
      <div class="clearfix"></div>
  </div>
</div>
<!-- end Topbar -->

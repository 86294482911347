
<div class="modal-content">
  <div class="modal-header border">
      <h4 class="modal-title font-weight-bold">Avaliação de Proposta</h4>
  </div>
  <div class="modal-body">
    <div class="row mt-2" style="max-height: 75vh; overflow-y: auto;">
      <div class="col">
        <p class="lead">
          Utilize o formulário abaixo para realizar a avaliação da proposta.
        </p>
        <div *ngFor="let topic of this.formAvaliation.topics">
          <h4 class="my-3">#{{ topic.name | uppercase }}</h4>
          <div class="my-3" *ngFor="let question of topic.questions; index as i">
            <p>{{ (i + 1) + '&#65114;'}} {{ question.text }}</p>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="radio mb-2" *ngFor="let option of question.options; index as j">
                    <input type="radio" name="question-{{question.id}}" id="question-{{question.id}}_{{option.type}}" [value]="option.type" [checked]="j == 0" (click)="onOptionSelected(question, option)">
                    <label for="question-{{question.id}}_{{option.type}}">{{ option.text }}</label>
                  </div>
                </div> <!-- end col -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border">
    <button type="button" class="btn btn-success" (click)="this.onSubmit()">Enviar</button>
    <button type="button" class="btn btn-danger" (click)="this.onClose()">Fechar</button>
  </div>
</div><!-- /.modal-content -->


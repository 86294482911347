import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Custom Interceptor
import { HeaderInterceptor } from '@core/interceptors/header-interceptor';

// Services
import { FormAvaliationService } from '@core/services/form-avaliation.service';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  exports: [ ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    FormAvaliationService
  ]
})
export class CoreModule {
  constructor(@SkipSelf() @Optional() parent: CoreModule){
    if (parent) {
      throw new Error(`${parent.constructor.name} has already been loaded.`);
  }
  }
}

import { AvaliationService } from './../../../core/services/avaliation.service';

import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

// Models
import { FormAvaliation } from '@models/form-avaliation.model';
import { Proposal } from '@models/proposal.model';
import { OptionFormAvaliation } from './../../../core/models/option-form-avaliation.model';
import { QuestionFormAvaliation } from './../../../core/models/question-form-avaliation.model';

// Interfaces
import { QuestionResultRequestDTO } from './../../../core/interfaces/dto/request/question-result-request-dto.interface';

// Enums
import { OptionFormAvaliationType } from './../../../core/enums/option-form-avaliation-type.enum';

// Thirds
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-avaliation',
  templateUrl: './modal-avaliation.component.html',
  styleUrls: ['./modal-avaliation.component.scss']
})
export class ModalAvaliationComponent implements OnInit, OnDestroy {

  @Input() proposal: Proposal;
  @Output() send: EventEmitter<boolean> = new EventEmitter(false);

  formAvaliation: FormAvaliation = new FormAvaliation();
  questionsResultRequestDTO: Array<QuestionResultRequestDTO> = new Array();

  // Subscription
  subscription: Subscription = new Subscription();

  constructor(public modalRef: BsModalRef, private avaliationService: AvaliationService) { }

   // Getter form control

  ngOnInit() {
    this.formAvaliation = this.proposal.campaign.formAvaliation;
    this.formAvaliation.topics.forEach(topic =>{
      topic.questions.forEach(question => {
        const questionsResultRequestDTO: QuestionResultRequestDTO = {optionType: OptionFormAvaliationType.NOT_APPLICABLE, optionValue: 0, question};
        this.questionsResultRequestDTO.push(questionsResultRequestDTO);
      });
    });
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  // Method to submit model to service
  onSubmit() {
    this.subscription.add(this.avaliationService.save(this.questionsResultRequestDTO, this.proposal).subscribe({
      next: avaliation => {
        Swal.fire('Avaliação Enviada', 'Avaliação enviada com sucesso', 'success');
        this.send.emit(true);
        this.onClose();
      },
      error: err => Swal.fire('Ooops!', `${err.error.message}`, 'error')
    }));
  }

  onClose(){
    this.modalRef.hide();
  }

  onOptionSelected(question: QuestionFormAvaliation, option: OptionFormAvaliation){
    const questionResultRequestDTO: QuestionResultRequestDTO = {optionValue: option.value, optionType: option.type, question};
    const index = this.questionsResultRequestDTO.findIndex(obj => obj.question.id === question.id);
    this.questionsResultRequestDTO[index] = questionResultRequestDTO;
  }

}

import { User } from '@models/user.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-modal-edit-user',
  templateUrl: './modal-edit-user.component.html',
  styleUrls: ['./modal-edit-user.component.scss']
})
export class ModalEditUserComponent implements OnInit {

  @Input() user: User;
  @Output() action: EventEmitter<boolean> = new EventEmitter();
  @Output() data: EventEmitter<User> = new EventEmitter();
  userEdit = new User();

  // Form
  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(public modalRef: BsModalRef, private formBuilder: FormBuilder) { }

  get f() { return this.form.controls; }

  ngOnInit(){
    this.initForm();
    this.userEdit = JSON.parse(JSON.stringify(this.user));
  }

  initForm(){
    this.form = this.formBuilder.group({
      name: new FormControl(this.userEdit.name, Validators.required),
      lastName: new FormControl(this.userEdit.lastName, Validators.required),
      phone: new FormControl(this.userEdit.phone),
      username: new FormControl(this.userEdit.credentials.username, Validators.required)
    });
  }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    if (this.form.invalid) { this.loading = false; return; }
    this.action.next(true);
    this.data.next(this.userEdit);
    this.modalRef.hide();
    this.loading = false;
  }

  onClose(){
    this.action.next(false);
    this.data.next(null);
    this.modalRef.hide();
  }
}

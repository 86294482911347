<!-- contact start -->
<section class="section pb-0 bg-gradient" id="contact">
  <div class="bg-shape">
      <img src="../../../../assets/images/bg-shape-light.png" alt="" class="img-fluid mx-auto d-block">
  </div>
  <div class="container-fluid">
      <div class="row">
          <div class="col-lg-12">
              <div class="title text-center mb-4">
                  <h3 class="text-white">Possui alguma dúvida ?</h3>
                  <p class="text-white">Entre em contato conosco através de nossos canais de comunicação.</p>
              </div>
          </div>
      </div>
      <!-- end row -->
      <div class="row mb-4">
          <div class="col-lg-4">
              <div class="contact-content text-center mt-4">
                  <div class="contact-icon mb-2">
                      <i class="far fa-envelope fa-2x text-white"></i>
                  </div>
                  <div class="contact-details text-white">
                      <h5 class="text-white">E-mail</h5>
                      <p class="text-white">contato@incentivados.com.br</p>
                  </div>
              </div>
          </div>
          <!-- end col -->
          <div class="col-lg-4">
              <div class="contact-content text-center mt-4">
                  <div class="contact-icon mb-2">
                      <i class="fab fa-whatsapp fa-2x text-white"></i>
                  </div>
                  <div class="contact-details">
                      <h5 class="text-white">WhatsApp</h5>
                      <p class="text-white">(11) 5555-5555</p>
                  </div>
              </div>
          </div>
          <!-- end col -->
          <div class="col-lg-4">
              <div class="contact-content text-center mt-4">
                  <div class="contact-icon mb-2">
                      <i class="fas fa-mobile-alt fa-2x text-white"></i>
                  </div>
                  <div class="contact-details">
                      <h5 class="text-white">Mídias Sociais</h5>
                      <p class="text-white">@incentivados</p>
                  </div>
              </div>
          </div>
          <!-- end col -->
      </div>
      <!-- end row -->

      <div class="row justify-content-center">
          <div class="col-lg-10">

              <div class="custom-form p-5 bg-white">
                  <div id="message"></div>
                  <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                      <div class="row">
                          <div class="col-lg-6">
                              <div class="form-group">
                                  <label for="name">Nome</label>
                                  <input name="name" id="name" type="text" class="form-control" placeholder="Digite seu nome ...">
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="form-group">
                                  <label for="email">E-mail</label>
                                  <input name="email" id="email" type="email" class="form-control" placeholder="Digite seu e-mail...">
                              </div>
                          </div>
                      </div>
                      <!-- end row -->

                      <div class="row">
                          <div class="col-lg-12">
                              <div class="form-group">
                                  <label for="subject">Assunto</label>
                                  <input name="subject" id="subject" type="text" class="form-control" placeholder="Digite seu assunto...">
                              </div>
                          </div>
                      </div>
                      <!-- end row -->

                      <div class="row">
                          <div class="col-lg-12">
                              <div class="form-group">
                                  <label for="comments">Mensagem</label>
                                  <textarea name="comments" id="comments" rows="4" class="form-control" placeholder="Digite sua mensagem..."></textarea>
                              </div>
                          </div>
                      </div>
                      <!-- end row -->

                      <div class="row">
                          <div class="col-lg-12 text-right">
                              <input type="submit" id="submit" name="send" class="btn btn-dark" value="Enviar Mensagem">
                              <div id="simple-msg"></div>
                          </div>
                      </div>
                      <!-- end row -->
                  </form>
              </div>
              <!-- end custom-form -->
          </div>
          <!-- end col -->
      </div>
      <!-- end row -->
  </div>
  <!-- end container-fluid -->
</section>
<!-- contact end -->

<!-- cta start -->
<section class="section-sm bg-light">
  <div class="container-fluid">
      <div class="row align-items-center">
          <div class="col-md-9">
              <h3 class="mb-0 mo-mb-20 text-orange-gradiente">Conectar propósitos, essa é nossa essência <i class="fas fa-heartbeat"></i></h3>
          </div>
          <div class="col-md-3">
              <div class="text-md-right">
                  <a href="#" class="btn btn-outline-dark btn-rounded"><i class="mdi mdi-email-outline mr-1"></i> Fale Conosco</a>
              </div>
          </div>
      </div>
      <!-- end row -->
  </div>
  <!-- end container-fluid -->
</section>
<!-- cta end -->

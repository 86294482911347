import { Component, OnInit} from '@angular/core';

import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  animations: [
    trigger('collapse', [
      state('small', style({ display: 'none'})),
      state('large', style({ display: 'block'})),

      transition('small <=> large', animate('400ms'))
    ])
  ]
})
export class NavbarComponent implements OnInit {

  collapse: string;

  constructor() {}

  ngOnInit(): void {

  }

  collapseMenu(){
    this.collapse = (this.collapse === 'large' ? 'small' : 'large');
  }
}

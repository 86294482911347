export enum OptionFormAvaliationType {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  YES = 'YES',
  NO = 'NO',
  TOO_BAD = 'TOO_BAD',
  BAD = 'BAD',
  REGULAR = 'REGULAR',
  GOOD = 'GOOD',
  TOO_GOOD = 'TOO_GOOD'
}

export const optionFormAvaliationTypeLabel = new Map<OptionFormAvaliationType, string>([
  [OptionFormAvaliationType.NOT_APPLICABLE, 'Não se Aplica'],
  [OptionFormAvaliationType.YES, 'Sim'],
  [OptionFormAvaliationType.NO, 'Não'],
  [OptionFormAvaliationType.TOO_BAD, 'Muito Ruim'],
  [OptionFormAvaliationType.BAD, 'Ruim'],
  [OptionFormAvaliationType.REGULAR, 'Regular'],
  [OptionFormAvaliationType.GOOD, 'Bom'],
  [OptionFormAvaliationType.TOO_GOOD, 'Muito Bom'],
]);

import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-default-template',
  templateUrl: './default-template.component.html',
  styleUrls: ['./default-template.component.css']
})
export class DefaultTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}

<!-- features start -->
<section class="section-sm pt-0" id="features">
  <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="text-center mb-4 pb-1">
            <h3 class="mb-3">Serviços Oferecidos</h3>
            <p class="text-muted">The clean and well commented code allows easy customization of the theme.It's designed for describing your app, agency or business.</p>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row">
          <div class="col-lg-4">
            <div class="features-box">
              <div class="features-img mb-4">
                <i class="fas fa-layer-group fa-4x text-p_dark-blue"></i>
              </div>
              <h4 class="text-p_dark-blue mb-2">Tecnologia & Agilidade</h4>
              <p class="text-p_dark-blue">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit</p>
            </div>
          </div>
          <!-- end col -->
          <div class="col-lg-4">
            <div class="features-box">
              <div class="features-img mb-4">
                <i class="far fa-handshake fa-4x text-p_dark-blue"></i>
              </div>
              <h4 class="text-p_dark-blue mb-2">Conexão com Stakeholders</h4>
              <p class="text-p_dark-blue">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium</p>
            </div>
          </div>
          <!-- end col -->
          <div class="col-lg-4">
            <div class="features-box">
              <div class="features-img mb-4">
                <i class="fas fa-sitemap fa-4x text-p_dark-blue"></i>
              </div>
              <h4 class="text-p_dark-blue mb-2">Gestão de Informações</h4>
              <p class="text-p_dark-blue">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>
            </div>
          </div>
          <!-- end col -->
      </div>
      <!-- end row -->

      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="features-box">
            <div class="features-img mb-4">
              <i class="far fa-chart-bar fa-4x text-p_dark-blue"></i>
            </div>
            <h4 class="text-p_dark-blue mb-2">Relatórios & Indicadores</h4>
            <p class="text-p_dark-blue">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>
          </div>
        </div>
        <!-- end col -->
        <div class="col-lg-4">
          <div class="features-box">
            <div class="features-img mb-4">
              <i class="far fa-check-circle fa-4x text-p_dark-blue"></i>
            </div>
            <h4 class="text-p_dark-blue mb-2">Simples & Eficiente</h4>
            <p class="text-p_dark-blue">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>
          </div>
        </div>
        <!-- end col -->
        <div class="col-lg-4">
          <div class="features-box">
            <div class="features-img mb-4">
              <i class="far fas fa-robot fa-4x text-p_dark-blue"></i>
            </div>
            <h4 class="text-p_dark-blue mb-3">Suporte Personalizado</h4>
            <p class="text-p_dark-blue">If several languages coalesce the grammar of the is more simple languages.</p>
          </div>
        </div>
        <!-- end col -->
      </div> <!-- end row -->
  </div> <!-- end container-fluid -->
</section>
<!-- features end -->

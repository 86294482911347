import { Directive, OnInit, ElementRef, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[appStickyMenu]'
})
export class StickyMenuDirective implements OnInit {

  constructor(private el: ElementRef) {}

  @HostBinding('class.nav-sticky') navSticky: boolean;

  @HostListener('window:scroll', ['$event']) onScroll(){
    this.navSticky = window.scrollY > 50 || window.screen.width < 992 ? true : false;
  }

  ngOnInit() {
  }
}

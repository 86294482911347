<div>
  <app-navbar></app-navbar>
  <app-hero-section></app-hero-section>
  <app-clients-section></app-clients-section>
  <app-about-section></app-about-section>
  <app-features-section></app-features-section>
  <app-pricing-section></app-pricing-section>
  <app-faq-section></app-faq-section>
  <app-testimonials-section></app-testimonials-section>
  <app-contact-setion></app-contact-setion>
  <app-footer></app-footer>
</div>

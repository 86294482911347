import { User } from '@models/user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Models
import { Tenant } from '@models/tenant.model';

// Interface
import { ResponsePageableDTO } from '@interfaces/dto/response/response-pageable-dto.interface';

// Enviroments
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  private baseURL = `${environment.baseUrlApi}/${environment.versionApi}`;

  constructor(private http: HttpClient) { }

  add(tenant: Tenant) {
    return this.http.post<Tenant>(`${this.baseURL}/admin/tenant`, tenant);
  }

  uploadFile(formData: FormData, tenant: Tenant){
    return this.http.post(`${this.baseURL}/admin/tenant/${tenant.id}/upload`, formData, {reportProgress: true, observe: 'events'});
  }

  findById(id: number){
    return this.http.get<Tenant>(`${this.baseURL}/moderator/tenant/${id}`);
  }

  findAll() {
    return this.http.get<Array<Tenant>>(`${this.baseURL}/covered/tenants`);
  }

  findAllPageable(page: number, size: number, key: string){
    return this.http.get<ResponsePageableDTO<Tenant>>(`${this.baseURL}/moderator/tenants/pageable?page=${page}&size=${size}&key=${key}`);
  }

  delete(id: number){
    return this.http.delete<void>(`${this.baseURL}/admin/tenant/${id}`);
  }

  count() {
    return this.http.get<number>(`${this.baseURL}/moderator/tenants/count`);
  }

  hasModule(module: string, user: User) {
    return user.tenant.modules.some((obj: any) => obj === module);
  }

}

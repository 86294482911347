import { Legislation } from '@models/legislation.model';
import { FundAccountBank } from './funk-account-bank.model';
import { Campaign } from '@models/campaign.model';
import { Project } from '@models/project.model';
import { Archive } from '@models/archive.model';
import { Avaliation } from './avaliation.model';

export class Proposal {
  id: number;
  dateRegister: Date;
  fundingTerm: Date;
  requestedValue: number;
  name: string;
  totalValue: number;
  directBeneficiaries: number;
  indirectBeneficiaries: number;
  counterpart: string;
  objective: string;
  saleText: string;
  mean: number;
  fundAccountBank: FundAccountBank;
  campaign: Campaign;
  project: Project;
  legislations: Array<Legislation>;
  partners: Array<string>;
  files: Array<Archive>;
  avaliations: Array<Avaliation>;

  constructor() {
    this.id = null;
    this.dateRegister = new Date();
    this.fundingTerm = null;
    this.requestedValue = null;
    this.name = null;
    this.totalValue = null;
    this.directBeneficiaries = null;
    this.indirectBeneficiaries = null;
    this.counterpart = null;
    this.objective = null;
    this.saleText = null;
    this.mean = null;
    this.fundAccountBank = null;
    this.campaign = new Campaign();
    this.project = new Project();
    this.legislations = new Array();
    this.partners = new Array();
    this.files = new Array();
    this.avaliations = new Array();
  }

  set(project: Project) {
    this.id = null;
    this.dateRegister = new Date();
    this.fundingTerm = null;
    this.requestedValue = null;
    this.name = project.name;
    this.totalValue = project.totalValue;
    this.directBeneficiaries = project.directBeneficiaries;
    this.indirectBeneficiaries = project.indirectBeneficiaries;
    this.counterpart = project.counterpart;
    this.objective = project.objective;
    this.mean = null;
    this.fundAccountBank = project.fundAccountBank;
    this.campaign = new Campaign();
    this.project = project;
    this.legislations = project.legislations;
    this.partners = project.partners;
    this.files = new Array();
    this.avaliations = new Array();
  }

}

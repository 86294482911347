import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

// Models
import { FormAvaliation } from '@models/form-avaliation.model';
import { Campaign } from '@models/campaign.model';

// Services
import { FormAvaliationService } from '@services/form-avaliation.service';
import { CampaignService } from '@services/campaign.service';

// Thirds
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-form-avaliation-fetch',
  templateUrl: './modal-form-avaliation-fetch.component.html',
  styleUrls: ['./modal-form-avaliation-fetch.component.scss']
})
export class ModalFormAvaliationFetchComponent implements OnInit, OnDestroy {

  @Input()
  campaign: Campaign;

  @Output()
  submitted: EventEmitter<boolean> = new EventEmitter(false);

  // Observable
  _formsAvaliation: BehaviorSubject<Array<FormAvaliation>> = new BehaviorSubject(new Array());
  formsAvaliation$: Observable<Array<FormAvaliation>> = this._formsAvaliation.asObservable();

  // Subscription
  subscription: Subscription = new Subscription();

  // Utils
  selectedFormAvaliation = null;
  error = false;

  constructor(public modalRef: BsModalRef, private formAvaliationService: FormAvaliationService, private campaignService: CampaignService, private router: Router) { }

  ngOnInit() {
    this.findAll();
    if (this.campaign.formAvaliation != null){
      this.selectedFormAvaliation = this.campaign.formAvaliation.id;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  findAll(){
    this.subscription.add(this.formAvaliationService.findAll().subscribe({
      next: formsAvaliation => this._formsAvaliation.next(formsAvaliation)
    }));
  }

  fetchFormAvaliation(){
    Swal.fire({
      title: 'Vinculando Formulário ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.subscription.add(this.campaignService.fetchFormAvaliation(this.campaign, this.selectedFormAvaliation).subscribe({
      next: () => {
        this.onClose();
        this.submitted.next(true);
        Swal.fire('Formulário Vinculado', 'Formulário vinculado com sucesso!', 'success');
        this.router.navigate([`./painel/campanhas/${this.campaign.id}`]);
      },
      error: err => Swal.fire('Ooops!', `${err.error.message}`, 'error')
    }));
  }

  onSubmit(){
    if (this.selectedFormAvaliation === null){
      return this.error = true;
    }
    this.fetchFormAvaliation();
  }

  onSelected(id: number){
    this.selectedFormAvaliation = id;
  }

  onClose() {
    this.modalRef.hide();
  }

}

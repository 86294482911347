import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';

// Services
import { TenantService } from '@services/tenant.service';
import { AuthService } from '@core/auth/auth.service';

// Enums
import { CredentialType } from '@enums/credentials-type.enum';

@Directive({
  selector: '[appHasModule]'
})
export class HasModuleDirective implements OnInit {

  // Utils
  modules: Array<string>;
  userType: CredentialType;

  constructor(private templateRef: TemplateRef<any>, private authService: AuthService, private tenantService: TenantService, private viewContainer: ViewContainerRef) { }

  @Input()
  set appHasModule(modules: Array<string>) {
      if (!modules || !modules.length) {
          throw new Error('Modules value is empty or missed');
      }
      this.modules = modules;
  }


  async ngOnInit() {
    this.userType = this.authService.getType();
    if (this.userType === CredentialType.ENTERPRISE || this.userType === CredentialType.GUEST){
      await this.getUser().then(user => {
        const hasModule = this.modules.some(module => this.tenantService.hasModule(module, user));
        if (hasModule) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
    }
    else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  getUser(){
    return this.authService.getCurrentUser().toPromise();
  }
}

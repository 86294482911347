import { AssociationType } from './../enums/association-type.enum';
import { User } from './user.model';
import { ArchiveStatus } from './../enums/archive-status.enum';
import { Address } from '@models/address.model';
import { AccountBank } from '@models/acount-bank.model';
import { Archive } from '@models/archive.model';

export class Association {
  id: number;
  dateRegister: Date;
  companyName: string;
  website: string;
  type: AssociationType;
  cnpj: string;
  about: string;
  status: ArchiveStatus;
  address: Address;
  user: User;
  accountBank: AccountBank;
  files: Array<Archive>;
  projects: Array<any>;
  orders: Array<any>;

  constructor(){
    this.id = null;
    this.dateRegister = new Date();
    this.companyName = null;
    this.website = null;
    this.type = null;
    this.cnpj = null;
    this.about = null;
    this.status = ArchiveStatus.PENDING;
    this.address = new Address();
    this.user = new User();
    this.accountBank = new AccountBank();
    this.files = new Array<Archive>();
    this.projects = new Array<any>();
    this.orders = new Array<any>();
  }
}

import { CredentialType } from '@enums/credentials-type.enum';

export class Credentials {
  id: number;
  username: string;
  password: string;
  type: CredentialType;
  dateRegister: Date;
  lastAccess: Date;
  activateToken: string;
  enabled: boolean;
  resetPasswordToken: string;

  constructor(){
    this.type = CredentialType.ASSOCIATION;
    this.dateRegister = new Date();
  }
}

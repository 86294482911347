<div class="row">
  <div class="col-lg-12">
    <!-- Portlet card -->
    <div class="card">
        <div class="card-header bg-primary py-3 text-white">
            <div class="card-widgets">
                <a (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseEvent"><i class="mdi mdi-minus"></i></a>
            </div>
            <h5 class="card-title mb-0 text-white">#{{ index }} - <u>{{ topic.name }}</u></h5>
        </div>
        <div class="question-box collapse show" id="collapseEvent" [isAnimated]="true" [collapse]="isCollapsed">
            <div class="card-body border">
              <ng-container class="border" *ngFor="let question of topic.questions; index as i">
                <p class="text-justify">{{ i + 1 }} - {{ question.text }}</p>
                <ul>
                  <li *ngFor="let option of question.options">{{ option.text }}</li>
                </ul>
                <ng-container *ngIf="i + 1 < topic.questions.length">
                  <hr>
                </ng-container>
              </ng-container>
            </div>
        </div>
    </div> <!-- end card-->
  </div><!-- end col -->
</div>

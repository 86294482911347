<!-- pricing start -->
<section class="section bg-gradient" id="pricing">
  <div class="bg-shape">
      <img src="../../../../assets/images/bg-shape.png" alt="" class="img-fluid mx-auto d-block">
  </div>
  <div class="container-fluid">
      <div class="row justify-content-center">
          <div class="col-lg-6">
              <div class="text-center mb-4">
                  <h3 class="text-white">Escolha seu Plano</h3>
                  <p class="text-white">The clean and well commented code allows easy customization of the theme.It's designed for describing your app, agency or business.</p>
              </div>
          </div>
      </div>
      <!-- end row -->

      <div class="row justify-content-center">
          <div class="col-xl-10">
              <div class="row align-items-center">
                  <div class="col-lg-4">
                      <div class="pricing-plan bg-white p-4 mt-4">
                          <div class="pricing-header text-center">
                              <h5 class="plan-title text-uppercase mb-4">Freemium</h5>
                              <h1><sup><small>R$</small></sup>49,99</h1>
                              <div class="plan-duration text-muted">30 dias Gratuitos</div>
                          </div>
                          <ul class="list-unstyled pricing-list mt-4">
                              <li>
                                  <i class="mdi mdi-album"></i>
                                  <p>Até <b>3</b> usuários</p>
                              </li>
                              <li>
                                  <i class="mdi mdi-lifebuoy"></i>
                                  <p>Até <b>4</b> campanhas</p>
                              </li>
                              <li>
                                  <i class="mdi mdi-update"></i>
                                  <p>Relatórios & Indicadores</p>
                              </li>
                              <li>
                                  <i class="mdi mdi-alarm-check"></i>
                                  <p>Suporte 24x7</p>
                              </li>
                          </ul>
                          <div class="text-center mt-5">
                              <a href="#" class="btn shadow btn-dark">Contratar</a>
                          </div>
                      </div>
                  </div> <!-- end col -->

                  <div class="col-lg-4">
                      <div class="pricing-plan active p-4 mt-4">
                          <span class="lable">Sugerido</span>
                          <div class="pricing-header text-white text-center">
                              <h5 class="plan-title text-white text-uppercase mb-4">Gold</h5>
                              <h1 class=" text-white"><sup><small>R$</small></sup>99,99</h1>
                              <div class="plan-duration">30 dias Gratuito</div>
                          </div>
                          <ul class="list-unstyled text-white pricing-list mt-4">
                            <li>
                              <i class="mdi mdi-album"></i>
                              <p>Até <b>10</b> usuários</p>
                            </li>
                            <li>
                                <i class="mdi mdi-lifebuoy"></i>
                                <p>Campanhas ilimitadas</p>
                            </li>
                            <li>
                                <i class="mdi mdi-update"></i>
                                <p>Relatórios & Indicadores</p>
                            </li>
                            <li>
                                <i class="mdi mdi-alarm-check"></i>
                                <p>Suporte Dedicado 24x7</p>
                            </li>
                          </ul>
                          <div class="text-center mt-5">
                              <a href="#" class="btn shadow btn-light">Adquirir Agora</a>
                          </div>
                      </div>
                  </div> <!-- end col -->

                  <div class="col-lg-4">
                      <div class="pricing-plan bg-white p-4 mt-4">
                          <div class="pricing-header text-center">
                              <h5 class="plan-title text-uppercase mb-4">Diamond</h5>
                              <h1><sup><small>R$</small></sup>199,99</h1>
                              <div class="plan-duration text-muted">Contate um Especialista</div>
                          </div>
                          <ul class="list-unstyled pricing-list mt-4">
                            <li>
                                <i class="mdi mdi-album"></i>
                                <p>Usuários Ilimitados</p>
                            </li>
                            <li>
                                <i class="mdi mdi-lifebuoy"></i>
                                <p>Campanhas Ilimitadas</p>
                            </li>
                            <li>
                                <i class="mdi mdi-update"></i>
                                <p>Relatórios & Indicadores</p>
                            </li>
                            <li>
                                <i class="mdi mdi-alarm-check"></i>
                                <p>Suporte Dedicado 24x7</p>
                            </li>
                          </ul>
                          <div class="text-center mt-5">
                              <a href="#" class="btn shadow btn-dark">Contratar</a>
                          </div>
                      </div>
                  </div> <!-- end col -->
              </div>
              <!-- end row -->
          </div> <!-- end col-xl-10 -->

      </div>
      <!-- end row -->
  </div>
  <!-- end container-fluid -->
</section>
<!-- pricing end -->

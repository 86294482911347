<!-- faqs start -->
<section class="section" id="faq">
  <div class="container-fluid">

      <div class="row justify-content-center">
          <div class="col-lg-6">
              <div class="text-center mb-5">
                  <h3>Curioso em saber como resolver seus problemas?</h3>
                  <p class="text-muted">Agende uma demonstração gratuita com nossa equipe de técnicos treinado para mostrar um novo mundo cheio de soluções.</p>
              </div>
          </div>
      </div>
      <!-- end row -->

      <div class="row">
          <div class="col-lg-5 offset-lg-1">
              <!-- Question/Answer -->
              <div>
                  <div class="faq-question-q-box">Q.</div>
                  <h4 class="faq-question">What is Lorem Ipsum?</h4>
                  <p class="faq-answer mb-4 pb-1 text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
              </div>

              <!-- Question/Answer -->
              <div>
                  <div class="faq-question-q-box">Q.</div>
                  <h4 class="faq-question">Why use Lorem Ipsum?</h4>
                  <p class="faq-answer mb-4 pb-1 text-muted">Lorem ipsum dolor sit amet, in mea nonumes dissentias dissentiunt, pro te solet oratio iriure. Cu sit consetetur moderatius intellegam, ius decore accusamus te. Ne primis suavitate disputando nam. Mutat convenirete.</p>
              </div>

              <!-- Question/Answer -->
              <div>
                  <div class="faq-question-q-box">Q.</div>
                  <h4 class="faq-question">How many variations exist?</h4>
                  <p class="faq-answer mb-4 pb-1 text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
              </div>

          </div>
          <!--/col-lg-5 -->

          <div class="col-lg-5">
              <!-- Question/Answer -->
              <div>
                  <div class="faq-question-q-box">Q.</div>
                  <h4 class="faq-question">Is safe use Lorem Ipsum?</h4>
                  <p class="faq-answer mb-4 pb-1 text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
              </div>

              <!-- Question/Answer -->
              <div>
                  <div class="faq-question-q-box">Q.</div>
                  <h4 class="faq-question">When can be used?</h4>
                  <p class="faq-answer mb-4 pb-1 text-muted">Lorem ipsum dolor sit amet, in mea nonumes dissentias dissentiunt, pro te solet oratio iriure. Cu sit consetetur moderatius intellegam, ius decore accusamus te. Ne primis suavitate disputando nam. Mutat convenirete.</p>
              </div>

              <!-- Question/Answer -->
              <div>
                  <div class="faq-question-q-box">Q.</div>
                  <h4 class="faq-question">License &amp; Copyright</h4>
                  <p class="faq-answer mb-4 pb-1 text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
              </div>

          </div>
          <!--/col-lg-5-->
      </div>
      <!-- end row -->

      <div class="row mt-5">
        <div class="col-12 text-center">
          <button class="btn btn-grad-primary">Agende uma Demonstração</button>
        </div>
      </div>

  </div> <!-- end container-fluid -->
</section>
<!-- faqs end -->

import { Feedback } from './feedback.model';
import { ArchiveStatus } from '../enums/archive-status.enum';
import { DocumentType } from '@enums/document-type.enum';

export class Archive {
  id: number;
  dateRegister: Date;
  name: string;
  absolutePath: string;
  relativePath: string;
  extension: string;
  size: number;
  type: DocumentType;
  status: ArchiveStatus;
  file: File;
  feedback: Feedback;

  constructor(){
    this.id = null;
    this.dateRegister = new Date();
    this.name = null;
    this.absolutePath = null;
    this.relativePath = null;
    this.extension = null;
    this.size = null;
    this.type = null;
    this.status = null;
    this.file = null;
    this.feedback = new Feedback();
  }

  setFileToRequest(type: DocumentType, file: File){
    const archive = new Archive();
    archive.type = type;
    archive.file = file;
    return archive;
  }

}

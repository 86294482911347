import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Models
import { Campaign } from '@models/campaign.model';

// Interfaces
import { ResponsePageableDTO } from '@interfaces/dto/response/response-pageable-dto.interface';

// Enums
import { CampaignStatus } from '@enums/campaign-status.enum';

// Services
import { ProposalService } from '@services/proposal.service';

// Enviroments
import { environment } from '@environments/environment';
import { Proposal } from '../models/proposal.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private baseURL = `${environment.baseUrlApi}/${environment.versionApi}`;

  constructor(private proposalService: ProposalService, private http: HttpClient) { }

  add(campaign: Campaign, tenantId: number){
    return this.http.post<Campaign>(`${this.baseURL}/enterprise/campaign?tenantId=${tenantId}`, campaign);
  }

  checkProposals2Curator(campaign: Campaign, proposalsSelected: Array<Proposal>) {
    const proposalsId = proposalsSelected.map(proposal => proposal.id);
    return this.http.post<Campaign>(`${this.baseURL}/moderator/campaign/${campaign.id}/curator?proposalsId=${proposalsId}`, null);
  }

  updateStatus(campaign: Campaign, status: CampaignStatus){
    return this.http.put<void>(`${this.baseURL}/moderator/campaign/${campaign.id}/status?status=${status}`, null);
  }

  fetchFormAvaliation(campaign: Campaign, formAvaliationId: number){
    return this.http.put<void>(`${this.baseURL}/moderator/campaign/${campaign.id}/form-avaliation/${formAvaliationId}`, null);
  }

  findById(id: number){
    return this.http.get<Campaign>(`${this.baseURL}/covered/campaign/${id}`);
  }

  delete(id: number){
    return this.http.delete<void>(`${this.baseURL}/admin/campaign/${id}`);
  }

  findAllPageable(page: number, size: number, tenantId: number, areaId: number, status: Array<CampaignStatus>){
    if (tenantId !== null && areaId !== null){
      return this.http.get<ResponsePageableDTO<Campaign>>(`${this.baseURL}/covered/campaigns/pageable?page=${page}&size=${size}&tenantId=${tenantId}&areaId=${areaId}&status=${status}`);
    }
    else if (tenantId !== null && areaId === null) {
      return this.http.get<ResponsePageableDTO<Campaign>>(`${this.baseURL}/covered/campaigns/pageable?page=${page}&size=${size}&tenantId=${tenantId}&status=${status}`);
    }
    else if (tenantId === null && areaId !== null){
      return this.http.get<ResponsePageableDTO<Campaign>>(`${this.baseURL}/covered/campaigns/pageable?page=${page}&size=${size}&areaId=${areaId}&status=${status}`);
    }
    else {
      return this.http.get<ResponsePageableDTO<Campaign>>(`${this.baseURL}/covered/campaigns/pageable?page=${page}&size=${size}&status=${status}`);
    }
  }

  count(status?: CampaignStatus){
    if (status === undefined){
      return this.http.get<number>(`${this.baseURL}/covered/campaign/count`);
    }
    else {
      return this.http.get<number>(`${this.baseURL}/covered/campaign/count?status=${status}`);
    }
  }

  sortProposalsByMean(campaign: Campaign) {
    campaign.proposals.forEach(proposal => this.proposalService.getMean(proposal));
    campaign.proposals.sort((a, b) => a.mean < b.mean ? 1 : -1);
  }

}

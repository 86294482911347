import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// Modals
import { User } from '@models/user.model';

// Services
import { AuthService } from '@core/auth/auth.service';
import { ViaCepService } from '@shared/via-cep/via-cep.service';

// Enums
import { CredentialType } from '@enums/credentials-type.enum';

// Thirds
import Swal from 'sweetalert2';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-save-user-association',
  templateUrl: './modal-save-user-association.component.html',
  styleUrls: ['./modal-save-user-association.component.scss']
})
export class ModalSaveUserAssociationComponent implements OnInit, OnDestroy {

  // Models
  user: User = new User();

  // Form
  formUser: FormGroup;
  submittedUser = false;
  formAddress: FormGroup;
  submittedAddress = false;
  loading = false;
  step: 'user' | 'address' = 'user';

  // Subscription
  subscription: Subscription = new Subscription();

  constructor(public modalRef: BsModalRef, private authService: AuthService, private viaCepService: ViaCepService, private formBuilder: FormBuilder) { }

  get fu() { return this.formUser.controls; }
  get fa() { return this.formAddress.controls; }

  ngOnInit(){
    this.initForm();
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  initForm(){
    this.user.credentials.type = CredentialType.ASSOCIATION;
    this.formUser = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      cpf: new FormControl(null, Validators.required),
      phone: new FormControl(null),
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
    this.formAddress = this.formBuilder.group({
      zipCode: new FormControl(null, Validators.required),
      publicPlace: new FormControl(null, Validators.required),
      number: new FormControl(null),
      complement: new FormControl(null),
      neighborhood: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
    });
    this.fa.publicPlace.disable();
    this.fa.complement.disable();
    this.fa.number.disable();
    this.fa.neighborhood.disable();
    this.fa.city.disable();
    this.fa.state.disable();
  }

  onSubmit(){
    this.submittedAddress = true;
    this.loading = true;
    if (this.formUser.invalid || this.formAddress.invalid) { this.loading = false; return; }
    Swal.fire({
      title: 'Enviando ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.subscription.add(this.authService.register(this.user).subscribe({
      next: () => {
        this.onClose();
        Swal.fire('Cadastro Realizado', `Para acessar a plataforma, verifique seu e-mail para validar seu acesso.`, 'success');
      },
      error: err => Swal.fire('Falha Cadastro', `${err.error.message}`, 'error'),
      complete: () => this.loading = false
    }));
  }

  onClose(){
    this.modalRef.hide();
  }

  searchCep(){
    if (this.fa.zipCode.errors){
      Swal.fire('Ooops', 'Cep inválido!', 'error');
      return;
    }
    Swal.fire({
      title: 'Buscando ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.subscription.add(this.viaCepService.findByZipCode(this.user.address.zipCode).subscribe({
      next: data => {
        if (data.erro){
          this.user.address.zipCode = null;
          Swal.fire('Ooops', 'Cep não localizado!', 'error');
        }
        else {
          this.user.address.parse(data);
        }
      },
      complete: () => {
        Swal.close();
        this.fa.publicPlace.enable();
        this.fa.complement.enable();
        this.fa.number.enable();
      }
    }));
  }

  changeStep(){
    if (this.step === 'user') {
      this.submittedUser = true;
      if (this.formUser.invalid){
        return;
      }
      this.step = 'address';
    }
    else { this.step = 'user'; }
  }

}

import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

// Model
import { FormAvaliation } from '@models/form-avaliation.model';

// Modal
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-form-avaliation-detail',
  templateUrl: './modal-form-avaliation-detail.component.html',
  styleUrls: ['./modal-form-avaliation-detail.component.scss']
})
export class ModalFormAvaliationComponentDetailComponent implements OnInit {

  @Input() formAvaliation: FormAvaliation;

  constructor(public modalRef: BsModalRef) { }

  ngOnInit() {
  }

  onClose(){
    this.modalRef.hide();
  }

}

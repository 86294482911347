import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { AuthService } from '@core/auth/auth.service';

// Models
import { User } from '@models/user.model';
import { Tenant } from '@models/tenant.model';

// Enums
import { CredentialType } from '@enums/credentials-type.enum';

// Thirds
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-save-user-guest',
  templateUrl: './modal-save-user-guest.component.html',
  styleUrls: ['./modal-save-user-guest.component.scss']
})
export class ModalSaveUserGuestComponent implements OnInit {

  @Input() tenant: Tenant;
  @Output() withSuccess: EventEmitter<boolean> = new EventEmitter(false);

  // Models
  user: User = new User();

  // Form
  form: FormGroup;
  loading = false;
  submitted = false;

  subscription: Subscription = new Subscription();

  constructor(public modalRef: BsModalRef, private authService: AuthService, private formBuilder: FormBuilder) { }

  get f() { return this.form.controls; }

  ngOnInit(){
    this.initForm();
  }

  initForm(){
    this.user.credentials.type = CredentialType.ENTERPRISE;
    this.form = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  addUser(){
    Swal.fire({
      title: 'Enviando ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.subscription.add(this.authService.addUserGuest(this.user, this.tenant).subscribe({
      next: () => {
        Swal.fire('Convidado Cadastrado', 'Convidado cadastrado com sucesso!', 'success');
        this.withSuccess.emit(true);
      },
      error: err => Swal.fire('Ooops!', `${err.error.message}`, 'error')
    }));
  }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    if (this.form.invalid) { this.loading = false; return; }
    this.addUser();
    this.modalRef.hide();
    this.loading = false;
  }

  onClose(){
    this.modalRef.hide();
  }


}

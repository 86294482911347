<!-- start page title -->
<div class="row">
  <div class="col-12">
      <div class="page-title-box">
          <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumb" [ngClass]="{'active': breadcrumb.active}">
                  <a *ngIf="!breadcrumb.active" [attr.href]="breadcrumb.link">{{ breadcrumb.name }} </a>
                  <span *ngIf="breadcrumb.active">{{ breadcrumb.name }}</span>
                </li>
              </ol>
          </div>
          <h4 class="page-title">{{ title }}</h4>
      </div>
  </div>
</div>
<!-- end page title -->

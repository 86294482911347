import { ViaCepInterface } from '@interfaces/via-cep.interface';

export class Address {
  id: number;
  publicPlace: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  zipCode: string;

  constructor(){
    this.id = null;
    this.publicPlace = null;
    this.number = null;
    this.complement = null;
    this.neighborhood = null;
    this.city = null;
    this.state = null;
    this.zipCode = null;
  }

  public parse(data: ViaCepInterface){
    this.publicPlace = data.logradouro;
    this.complement = data.complemento;
    this.neighborhood = data.bairro;
    this.city = data.localidade;
    this.zipCode = data.cep;
    switch (data.uf) {
      case 'AC':
        this.state = 'Acre';
        break;
      case 'AL':
        this.state = 'Alagoas';
        break;
      case 'AP':
        this.state = 'Amapá';
        break;
      case 'AM':
        this.state = 'Amazonas';
        break;
      case 'BA':
        this.state = 'Bahia';
        break;
      case 'CE':
        this.state = 'Ceará';
        break;
      case 'DF':
        this.state = 'Distrito Federal';
        break;
      case 'ES':
        this.state = 'Espí­rito Santo';
        break;
      case 'GO':
        this.state = 'Goiás';
        break;
      case 'MA':
        this.state = 'Maranhão';
        break;
      case 'MT':
        this.state = 'Mato Grosso';
        break;
      case 'MS':
        this.state = 'Mato Grosso do Sul';
        break;
      case 'MG':
        this.state = 'Minas Gerais';
        break;
      case 'PA':
        this.state = 'Pará';
        break;
      case 'PB':
        this.state = 'Paraíba';
        break;
      case 'PR':
        this.state = 'Paraná';
        break;
      case 'PE':
        this.state = 'Pernambuco';
        break;
      case 'PI':
        this.state = 'Piauí­';
        break;
      case 'RJ':
        this.state = 'Rio de Janeiro';
        break;
      case 'RN':
        this.state = 'Rio Grande do Norte';
        break;
      case 'RS':
        this.state = 'Rio Grande do Sul';
        break;
      case 'RO':
        this.state = 'Rondônia';
        break;
      case 'RR':
        this.state = 'Roraima';
        break;
      case 'SC':
        this.state = 'Santa Catarina';
        break;
      case 'SP':
        this.state = 'São Paulo';
        break;
      case 'SE':
        this.state = 'Sergipe';
        break;
      case 'TO':
        this.state = 'Tocantins';
        break;
    }
  }

  reset(){
    this.id = 0;
    this.publicPlace = null;
    this.number = null;
    this.complement = null;
    this.neighborhood = null;
    this.city = null;
    this.state = null;
    this.zipCode = null;
  }
}

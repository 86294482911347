import { Directive, OnInit, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appToggleDashboardFullScreen]'
})
export class ToggleDashboardFullScreenDirective implements OnInit {

  constructor() { }

  get enabled() {
    return !!(
      this.doc.fullscreenElement ||
      this.doc.mozFullScreenElement ||
      this.doc.webkitFullscreenElement ||
      this.doc.msFullscreenElement
    );
  }

  private doc = document as FullScreenDocument;

  ngOnInit() { }

  @HostListener('click') toggle() {
    if (this.enabled) { this.leave(); }
    else { this.enter(); }
  }

  enter() {
    const el = this.doc.documentElement;
    if (el.requestFullscreen) { el.requestFullscreen(); }
    else if (el.msRequestFullscreen) { el.msRequestFullscreen(); }
    else if (el.mozRequestFullScreen) { el.mozRequestFullScreen(); }
    else if (el.webkitRequestFullscreen) { el.webkitRequestFullscreen(); }
  }

  leave() {
    if (this.doc.exitFullscreen) { this.doc.exitFullscreen(); }
    else if (this.doc.msExitFullscreen) { this.doc.msExitFullscreen(); }
    else if (this.doc.mozCancelFullScreen) { this.doc.mozCancelFullScreen(); }
    else if (this.doc.webkitExitFullscreen) { this.doc.webkitExitFullscreen(); }
  }
}

interface FullScreenDocument extends HTMLDocument {
  documentElement: FullScreenDocumentElement;
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

interface FullScreenDocumentElement extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

// Models
import { User } from '@models/user.model';

// Enums
import { CredentialType } from '@enums/credentials-type.enum';

// Services
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'app-left-bar-dashboard',
  templateUrl: './left-bar-dashboard.component.html',
  styleUrls: ['./left-bar-dashboard.component.css']
})
export class LeftBarDashboardComponent implements OnInit, OnDestroy {

  // Observable
  _user: BehaviorSubject<User> = new BehaviorSubject(new User());
  user$: Observable<User> = this._user.asObservable();

  // Subscription
  subscription: Subscription = new Subscription();

  credentialsType = Object.freeze(CredentialType);

  graphicsCollapse = true;

  options = { autoHide: true, scrollbarMinSize: 100 };

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser(){
    this.subscription.add(this.authService.getCurrentUser().subscribe(
      data => this._user.next(data)
    ));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import { User } from '@models/user.model';
import { Tenant } from '@models/tenant.model';
import { Credentials } from '@models/credentials.model';

// Interfaces
import { ResponsePageableDTO } from '@interfaces/dto/response/response-pageable-dto.interface';

// Enums
import { CredentialType } from '@enums/credentials-type.enum';

// Enviroments
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginURL = `${environment.baseUrlApi}/login`;
  private baseURL = `${environment.baseUrlApi}/${environment.versionApi}`;

  constructor(private http: HttpClient) {
  }

  register(user: User){
    return this.http.post<User>(`${this.baseURL}/user-association/register`, user, {headers: { skip: 'true' }});
  }

  addUserEnterprise(user: User, tenant: Tenant){
    user.credentials.type = CredentialType.ENTERPRISE;
    user.tenant = tenant;
    return this.http.post<User>(`${this.baseURL}/enterprise/users/register`, user);
  }

  addUserGuest(user: User, tenant: Tenant){
    user.credentials.type = CredentialType.GUEST;
    user.tenant = tenant;
    return this.http.post<User>(`${this.baseURL}/enterprise/guests/register`, user);
  }

  removeUserEnterprise(userId: number, tenant: Tenant){
    return this.http.delete<void>(`${this.baseURL}/admin/tenants/${tenant.id}/users/${userId}`);
  }

  login(username: string, password: string) {
    return this.http.post<any>(this.loginURL, { username, password });
  }

  logout(): void {
    localStorage.removeItem('crendentials');
    localStorage.removeItem('token');
    localStorage.removeItem('authorities');
  }

  active(token: string){
    return this.http.put<void>(`${this.baseURL}/active?token=${token}`, null, {headers: { skip: 'true' }});
  }

  recoveryPassword(username: string){
    return this.http.post<void>(`${this.baseURL}/recovery-password?username=${username}`, null, {headers: { skip: 'true' }});
  }

  getCurrentUser() {
    return this.http.get<User>(`${this.baseURL}/covered/users`);
  }

  update(user: User){
    return this.http.put<User>(`${this.baseURL}/covered/users/${user.id}`, user);
  }

  uploadAvatar(credentials: Credentials, formData: FormData){
    return this.http.post<Credentials>(`${this.baseURL}/covered/credentials/${credentials.id}/upload/avatar`, formData);
  }

  changePassword(newPassword: string){
    return this.http.put(`${this.baseURL}/covered/change-password?newPassword=${newPassword}`, null);
  }

  findAll(){
    return this.http.get<Array<User>>(`${this.baseURL}/moderator/users`);
  }

  findAllByCredentialsType(type: CredentialType){
    return this.http.get<Array<User>>(`${this.baseURL}/moderator/users/type/${type}`);
  }

  findAllGuestsByTenantIdPageable(tenantId: number, page: number, size: number){
    return this.http.get<ResponsePageableDTO<User>>(`${this.baseURL}/moderator/users/guests/pageable?tenantId=${tenantId}&page=${page}&size=${size}`);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getRoles(): Array<string> {
    return JSON.parse(localStorage.getItem('authorities'));
  }

  getType(){
    return JSON.parse(localStorage.getItem('crendentials')).type as CredentialType;
  }

  hasRole(role: string) {
    return this.getRoles().some((obj: any) => obj.role === 'ROLE_'.concat(role));
  }

  isLogged() {
    return !!localStorage.getItem('crendentials');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';

// Locale
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';

// Ngx
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxSummernoteModule } from 'ngx-summernote';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TagInputModule } from 'ngx-chips';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { DndListModule } from 'ngx-drag-and-drop-lists';

// Others Modules
import { ViaCepModule } from '@shared/via-cep/via-cep.module';
import { NgSwitcheryModule } from 'angular-switchery-ios';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AgmCoreModule } from '@agm/core';
import { SimplebarAngularModule } from 'simplebar-angular';

// Modal Components
import { ModalSaveUserEnterpriseComponent } from '@shared/modal/modal-save-user-enterprise/modal-save-user-enterprise.component';
import { ModalSaveUserAssociationComponent } from '@shared/modal/modal-save-user-association/modal-save-user-association.component';
import { ModalSaveUserGuestComponent } from './modal/modal-save-user-guest/modal-save-user-guest.component';
import { ModalEditUserComponent } from '@shared/modal/modal-edit-user/modal-edit-user.component';
import { ModalFeedbackDetailComponent } from '@shared/modal/modal-feedback-detail/modal-feedback-detail.component';
import { ModalAvaliationComponent } from './modal/modal-avaliation/modal-avaliation.component';
import { ModalFormAvaliationComponentDetailComponent } from '@shared/modal/modal-form-avaliation-detail/modal-form-avaliation-detail.component';
import { ModalFormAvaliationFetchComponent } from '@shared/modal/modal-form-avaliation-fetch/modal-form-avaliation-fetch.component';
import { ModalFormAvaliationTopicDetailComponent } from './modal/modal-form-avaliation-detail/modal-form-avaliation-topic-detail/modal-form-avaliation-topic-detail.component';
import { ModalSaleTextComponent } from './modal/modal-sale-text/modal-sale-text.component';

// Shared Components
import { BreadcrumbDashboardComponent } from '@shared/breadcrumb-dashboard/breadcrumb-dashboard.component';
import { PaginationComponent } from '@shared/pagination/pagination.component';

// Directives
import { ToggleDashboardMenuDirective } from '@shared/directives/toggle-dashboard-menu.directive';
import { ToggleDashboardFullScreenDirective } from '@shared/directives/toggle-dashboard-full-screen.directive';
import { StickyMenuDirective } from '@shared/directives/sticky-menu.directive';
import { HasRoleDirective } from '@shared/directives/has-role.directive';
import { HasModuleDirective } from '@shared/directives/has-module.directive';

// Pippes
import { AbsolutePathPipe } from '@shared/pipes/absolute-path.pipe';

// Enviroments
import { environment } from '@environments/environment';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
  clearMaskOnLostFocus: false
};

export const ngxModules = [
  ModalModule,
  NgxSummernoteModule,
  NgxBootstrapMultiselectModule,
  NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
  ToastrModule.forRoot({
    timeOut: 5000,
    progressBar: true,
    progressAnimation: 'increasing',
    preventDuplicates: true
  }),
  ModalModule.forRoot(),
  BsDatepickerModule.forRoot(),
  NgxMaskModule.forRoot(),
  MomentModule.forRoot(),
  TabsModule.forRoot(),
  TooltipModule.forRoot(),
  ProgressbarModule.forRoot(),
  CollapseModule.forRoot(),
  AccordionModule.forRoot(),
  DndListModule
];

export const otherModules = [
  NgSwitcheryModule,
  TagInputModule,
  ViaCepModule,
  NgApexchartsModule,
  SimplebarAngularModule,
  AgmCoreModule.forRoot({
    apiKey: environment.GoogleMapsAPIKEY
  }),
];

export const modalComponents =  [
  ModalFeedbackDetailComponent,
  ModalSaveUserAssociationComponent,
  ModalSaveUserEnterpriseComponent,
  ModalSaveUserGuestComponent,
  ModalEditUserComponent,
  ModalAvaliationComponent,
  ModalFormAvaliationComponentDetailComponent,
  ModalFormAvaliationFetchComponent,
  ModalSaleTextComponent
];

export const sharedComponents = [
  BreadcrumbDashboardComponent,
  PaginationComponent,
  ModalFormAvaliationTopicDetailComponent
];

export const sharedDirectives = [
  HasModuleDirective,
  HasRoleDirective,
  StickyMenuDirective,
  ToggleDashboardFullScreenDirective,
  ToggleDashboardMenuDirective
];

export const sharedPipes = [
  AbsolutePathPipe
];

@NgModule({
  declarations: [
    modalComponents,
    sharedComponents,
    sharedDirectives,
    sharedPipes
  ],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    NgbModule,
    ngxModules,
    otherModules

  ],
  exports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    NgbModule,
    ngxModules,
    otherModules,
    sharedComponents,
    sharedDirectives,
    sharedPipes
  ],
  providers: []
})
export class SharedModule {
  constructor(){
    defineLocale('pt-br', ptBrLocale);
  }
}

import { AboutSectionComponent } from './about-section/about-section.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

// Components
import { HomePageComponent } from '@pages/home-page/home-page.component';
import { NavbarComponent } from '@pages/home-page/navbar/navbar.component';
import { HeroSectionComponent } from '@pages/home-page/hero-section/hero-section.component';
import { ClientsSectionComponent } from '@pages/home-page/clients-section/clients-section.component';
import { FeaturesSectionComponent } from '@pages/home-page/features-section/features-section.component';
import { PricingSectionComponent } from '@pages/home-page/pricing-section/pricing-section.component';
import { FaqSectionComponent } from '@pages/home-page/faq-section/faq-section.component';
import { TestimonialsSectionComponent } from '@pages/home-page/testimonials-section/testimonials-section.component';
import { ContactSetionComponent } from '@pages/home-page/contact-setion/contact-setion.component';
import { FooterComponent } from '@pages/home-page/footer/footer.component';

@NgModule({
  declarations: [
    HomePageComponent, NavbarComponent, HeroSectionComponent, AboutSectionComponent, ClientsSectionComponent, FeaturesSectionComponent, PricingSectionComponent,
    FaqSectionComponent, TestimonialsSectionComponent, ContactSetionComponent, FooterComponent
  ],
  imports: [
    RouterModule,
    SharedModule
  ],
  exports: [
    HomePageComponent, NavbarComponent, HeroSectionComponent, AboutSectionComponent, ClientsSectionComponent, FeaturesSectionComponent, PricingSectionComponent,
    FaqSectionComponent, TestimonialsSectionComponent, ContactSetionComponent, FooterComponent
  ]
})
export class HomeModule{}

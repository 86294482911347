import { User } from './user.model';
import { TenantsModule } from '@pages/painel-page/tenants/tenants.module';
import { Archive } from '@models/archive.model';
import { Campaign } from './campaign.model';

export class Tenant{
  id: number;
  companyName: string;
  cnpj: string;
  website: string;
  files: Array<Archive>;
  campaigns: Array<Campaign>;
  modules: Array<TenantsModule>;
  users: Array<User>;
  guests: Array<User>;

  constructor() {
    this.id = null;
    this.companyName = null;
    this.cnpj = null;
    this.website = null;
    this.files = new Array<Archive>();
    this.campaigns = new Array<Campaign>();
    this.modules = new Array<TenantsModule>();
    this.users = new Array<User>();
    this.guests = new Array<User>();
  }
}

<!-- footer start -->
<footer class="bg-dark footer">
  <div class="container-fluid">
      <div class="row mb-5">
          <div class="col-lg-4">
              <div class="pr-lg-4">
                  <div class="mb-4">
                      <img class="logo-footer" src="/assets/images/logo-incentivados-horizontal-branco.png" alt="">
                  </div>
                  <p class="text-white">Na Yabá Consultoria respeitamos as diferenças e partimos do princípio de que é possível impactar a vida de muitas pessoas com dedicação e trabalho de qualidade.</p>
                  <button class="btn btn-white"><i class="fas fa-user-friends mr-2"></i> Área do Usuário</button>
              </div>
          </div>

          <div class="col-lg-2">
              <div class="footer-list">
                  <p class="text-white mb-2 footer-list-title">Links Rápidos</p>
                  <ul class="list-unstyled">
                    <li><a href="#"><i class="mdi mdi-chevron-right mr-2"></i>Home</a></li>
                    <li><a href="#"><i class="mdi mdi-chevron-right mr-2"></i>Sobre</a></li>
                    <li><a href="#"><i class="mdi mdi-chevron-right mr-2"></i>FAQ</a></li>
                  </ul>
              </div>
          </div>

          <div class="col-lg-3">
            <div class="footer-list">
                <p class="text-white mb-2 footer-list-title">Suporte</p>
                <ul class="list-unstyled">
                  <li><a href="#"><i class="mdi mdi-chevron-right mr-2"></i>Agende uma Demonstração</a></li>
                  <li><a href="#"><i class="mdi mdi-chevron-right mr-2"></i>Consultoria</a></li>
                  <li><a href="#"><i class="mdi mdi-chevron-right mr-2"></i>Contato</a></li>
                </ul>
            </div>
        </div>

          <div class="col-lg-3">
              <div class="footer-list">
                  <p class="text-white mb-2 footer-list-title">Outros Links</p>
                  <ul class="list-unstyled">
                      <li><a href="#"><i class="mdi mdi-chevron-right mr-2"></i>Políticas de Privacidade</a></li>
                      <li><a href="#"><i class="mdi mdi-chevron-right mr-2"></i>Termos & Condições</a></li>
                  </ul>
              </div>
          </div>

      </div>
      <!-- end row -->

      <div class="row">
          <div class="col-lg-12">
              <div class="float-left pull-none">
                  <p class="text-white">2019 - {{ this.actuallyYear }} © Incentivados. Desenvolvido por <a href="https://www.yabaconsultoria.com.br" target="_blank" class="text-white">Yabá Consultoria</a> </p>
              </div>
              <div class="float-right pull-none">
                  <ul class="list-inline social-links">
                      <li class="list-inline-item text-white">
                          Social :
                      </li>
                      <li class="list-inline-item"><a href="#"><i class="mdi mdi-facebook"></i></a></li>
                      <li class="list-inline-item"><a href="#"><i class="mdi mdi-twitter"></i></a></li>
                      <li class="list-inline-item"><a href="#"><i class="mdi mdi-instagram"></i></a></li>
                      <li class="list-inline-item"><a href="#"><i class="mdi mdi-google-plus"></i></a></li>
                  </ul>
              </div>
          </div>
          <!-- end col -->
      </div>
      <!-- end row -->
  </div>
  <!-- container-fluid -->
</footer>
<!-- footer end -->

export enum ArchiveStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  REQUEST_UPDATE = 'REQUEST_UPDATE',
  NOT_APPLY = 'NOT_APPLY'
}

export const archiveStatusLabel = new Map<ArchiveStatus, string>([
  [ArchiveStatus.PENDING, 'Pendente'],
  [ArchiveStatus.APPROVED, 'Regular'],
  [ArchiveStatus.DENIED, 'Irregular'],
  [ArchiveStatus.REQUEST_UPDATE, 'Renovar'],
  [ArchiveStatus.NOT_APPLY, 'Não se Aplica'],
]);

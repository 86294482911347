import { AuthService } from '@core/auth/auth.service';
import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit {

  roles: Array<string>;

  constructor(private templateRef: TemplateRef<any>, private authService: AuthService, private viewContainer: ViewContainerRef) { }

  @Input()
  set appHasRole(roles: Array<string>) {
      if (!roles || !roles.length) {
          throw new Error('Type value is empty or missed');
      }
      this.roles = roles;
  }


  ngOnInit() {
    const userRole = this.authService.getType();
    const hasRole = this.roles.some(type => type === userRole);
    if (hasRole) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}

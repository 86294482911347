
<div class="modal-content">
  <div class="modal-header border">
      <h4 class="modal-title font-weight-bold">Editar Usuário</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="this.form" class="needs-validation" novalidate>
      <div class="row">
        <div class="col-12">
          <div class="form-group mb-3">
            <label>Nome: </label>
            <input type="text" class="form-control" formControlName="name" [(ngModel)]="this.userEdit.name" placeholder="Ex: Roberto" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Campo <b>NOME</b> é obrigatório</div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group mb-3">
            <label>Sobrenome: </label>
            <input type="text" class="form-control" formControlName="lastName" [(ngModel)]="this.userEdit.lastName" placeholder="Ex: da Silva" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
              <div *ngIf="f.lastName.errors.required">Campo <b>SOBRENOME</b> é obrigatório</div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group mb-3">
            <label>Telefone: </label>
            <input type="text" class="form-control" mask="(00) 00000-0000" [dropSpecialCharacters]="false" formControlName="phone" [(ngModel)]="this.userEdit.phone" placeholder="Ex: (11) 95742-6666">
          </div>
        </div>
        <div class="col-12">
          <div class="form-group mb-3">
            <label>E-mail: </label>
            <input type="email" class="form-control" formControlName="username" [(ngModel)]="this.userEdit.credentials.username" placeholder="Ex: nome@domínio.com.br" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Campo <b>E-MAIL</b> é obrigatório</div>
              <div *ngIf="f.username.errors.email"><b>E-MAIL</b> Inválido</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border">
    <button type="button" class="btn btn-success" (click)="onSubmit()">Atualizar</button>
    <button type="button" class="btn btn-danger" (click)="onClose()">Cancelar</button>
  </div>
</div><!-- /.modal-content -->


<!-- clients start -->
<section>
  <div class="container-fluid">
      <div class="clients p-4 bg-white">
          <div class="row">
              <div class="col-md-3">
                  <div class="client-images">
                    <img src="../../../../assets/images/clients/logo-leroy-merlin.png" alt="logo-img" class="logo-client mx-auto img-fluid d-block">
                  </div>
              </div>
              <div class="col-md-3">
                  <div class="client-images">
                    <img src="../../../../assets/images/clients/logo-c6bank.png" alt="logo-img" class="logo-client mx-auto img-fluid d-block">
                  </div>
              </div>
              <div class="col-md-3">
                  <div class="client-images">
                    <img src="../../../../assets/images/clients/logo-elo.png" alt="logo-img" class="logo-client mx-auto img-fluid d-block">
                  </div>
              </div>
              <div class="col-md-3">
                  <div class="client-images">
                    <img src="../../../../assets/images/clients/logo-pfizer.png" alt="logo-img" class="logo-client mx-auto img-fluid d-block">
                  </div>
              </div>
          </div> <!-- end row -->
      </div>
  </div> <!-- end container-fluid -->
</section>
<!-- clients end -->

<div class="row">
  <div class="col-12">
      <div class="text-right">
          <ul class="pagination pagination-rounded justify-content-end">
            <li class="page-item mr-2" [ngClass]="{ 'disabled': empty || first}" (change)="setCurrentSize()">
              <select class="form-control" [(ngModel)]="this.size" style="height: 34px;">
                <option *ngFor="let size of sizeOptions" [ngValue]="size">{{ size }}</option>
              </select>
            </li>
            <li class="page-item" [ngClass]="{ 'disabled': empty || first}" (click)="firstPage()">
                <a class="page-link" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                    <span class="sr-only">Previous</span>
                </a>
            </li>
            <li class="page-item" *ngIf="empty" [ngClass]="{ 'active': empty}">
              <a class="page-link">1</a>
            </li>
            <ng-container *ngIf="!empty">
              <li class="page-item" *ngFor="let page of indexes" [ngClass]="{ 'active': page == getCurrentPage()}" (click)="setCurrentPage(page)">
                  <a class="page-link">{{ page + 1 }}</a>
              </li>
            </ng-container>
            <li class="page-item" [ngClass]="{ 'disabled': empty || last}" (click)="lastPage()">
                <a class="page-link" aria-label="Next">
                    <span aria-hidden="true">»</span>
                    <span class="sr-only">Next</span>
                </a>
            </li>
          </ul>
      </div>
  </div>
</div>
<!-- end row -->

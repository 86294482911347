// Models
import { Tenant } from '@models/tenant.model';
import { Area } from '@models/area.model';
import { FormAvaliation } from '@models/form-avaliation.model';
import { Proposal } from './proposal.model';
import { Archive } from '@models/archive.model';

// Enums
import { DocumentType } from '@enums/document-type.enum';
import { CampaignStatus } from '@enums/campaign-status.enum';

export class Campaign {
  id: number;
  dateRegister: Date;
  dateStart: Date;
  dateEnd: Date;
  name: string;
  contributionValue: number;
  description: string;
  status: CampaignStatus;
  tenant: Tenant;
  formAvaliation: FormAvaliation;
  areas: Array<Area>;
  requiredFiles: Array<DocumentType>;
  proposals: Array<Proposal>;
  selectedProposalsCurator: Array<Proposal>;
  regulation: Archive;

  constructor() {
    this.id = null;
    this.dateRegister = new Date();
    this.dateStart = new Date();
    this.dateEnd = null;
    this.name = null;
    this.contributionValue = null;
    this.description = null;
    this.status = CampaignStatus.WAITING_START;
    this.tenant = new Tenant();
    this.formAvaliation = null;
    this.areas = new Array();
    this.requiredFiles = new Array<DocumentType>();
    this.proposals = new Array();
    this.selectedProposalsCurator = new Array();
    this.regulation = new Archive();
  }

  setStatus() {
    if (new Date() < this.dateStart) { this.status = CampaignStatus.WAITING_START; }
    else { this.status = CampaignStatus.STARTED; }
  }

}

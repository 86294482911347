export enum CampaignStatus {
  WAITING_START = 'WAITING_START',
  STARTED = 'STARTED',
  ENDED = 'ENDED',
  FORM_AVALIATION = 'FORM_AVALIATION',
  IN_AVALIATION = 'IN_AVALIATION',
  PRE_CURATOR = 'PRE_CURATOR',
  IN_ANALYTICS = 'IN_ANALYTICS',
  MONITORING = 'MONITORING',
  FINISHED = 'FINISHED'
}

export const campaignStatusLabel = new Map<CampaignStatus, string>([
  [CampaignStatus.WAITING_START, 'Aguardando Início'],
  [CampaignStatus.STARTED, 'Em Andamento'],
  [CampaignStatus.ENDED, 'Encerrada'],
  [CampaignStatus.FORM_AVALIATION, 'Processando Indicadores'],
  [CampaignStatus.IN_AVALIATION, 'Em Avaliação'],
  [CampaignStatus.PRE_CURATOR, 'Pré-Comitê'],
  [CampaignStatus.IN_ANALYTICS, 'Análise Comitê'],
  [CampaignStatus.MONITORING, 'Monitoramento'],
  [CampaignStatus.FINISHED, 'Finalizada']
]);

import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';

// Models
import { User } from '@models/user.model';

// Services
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'app-top-bar-dashboard',
  templateUrl: './top-bar-dashboard.component.html',
  styleUrls: ['./top-bar-dashboard.component.css']
})
export class TopBarDashboardComponent implements OnInit, OnDestroy {

  _user: BehaviorSubject<User> = new BehaviorSubject(new User());
  user$: Observable<User> = this._user.asObservable();

  subscription: Subscription = new Subscription();

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.subscription.add(this.authService.getCurrentUser().subscribe({
      next: user => this._user.next(user)
    }));
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}

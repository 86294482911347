<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" appStickyMenu>
  <div class="container-fluid">
      <!-- LOGO -->
      <a class="logo text-uppercase" href="index.html">
          <img src="../../../../assets/images/logo-incentivados-branco.png" alt="" class="logo-light" height="60" />
          <img src="../../../../assets/images/logo-incentivados.png" alt="" class="logo-dark" height="60" />
      </a>

      <button class="navbar-toggler" type="button" (click)="collapseMenu();" >
        <i class="fas fa-bars text-dark"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse" [@collapse]="collapse">
          <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
              <li class="nav-item active">
                  <a href="#home" class="nav-link">Home</a>
              </li>
              <li class="nav-item">
                  <a href="#features" class="nav-link">Inscreva-se</a>
              </li>
              <li class="nav-item">
                <a href="#demo" class="nav-link">Sobre Nós</a>
              </li>
              <li class="nav-item">
                  <a href="#demo" class="nav-link">Perguntas Frequente</a>
              </li>

              <li class="nav-item">
                  <a href="#contact" class="nav-link">Contato</a>
              </li>
          </ul>
          <button [routerLink]="['/login']" class="btn btn-rounded btn-light navbar-btn">Entrar</button>
      </div>
  </div>
</nav>
<!-- Navbar End -->

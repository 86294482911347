
<div class="modal-content">
  <div class="modal-header border">
      <h4 class="modal-title font-weight-bold">Adicionar Texto de Venda</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="this.form" class="needs-validation" novalidate>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group mb-3">
            <label>Texto de Venda:</label>
            <textarea  class="form-control" formControlName="saleText" [(ngModel)]="this.proposal.saleText" placeholder="Ex: Digite o seu texto ..."
                rows="10" [ngClass]="{ 'is-invalid': submitted && f.saleText.errors }">{{ this.proposal.saleText }}</textarea>
            <div *ngIf="submitted && f.saleText.errors" class="invalid-feedback">
              <div *ngIf="f.saleText.errors.required">Campo <b>TEXTO DE VENDA</b> é obrigatório</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border">
    <button type="button" class="btn btn-success" (click)="onSubmit()">Enviar</button>
    <button type="button" class="btn btn-danger" (click)="onClose()">Fechar</button>
  </div>
</div><!-- /.modal-content -->
